<template>
  <div>
    <template v-if="problemsList.length">
      <div
        v-for="(problemLevelValue, problemLevelName) in mappedProblems"
        :key="problemLevelName"
      >
        <h3> {{ problemLevelName }}: {{ problemLevelValue.allCount }} </h3>

        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(problemTypeValue, problemTypeName) in problemLevelValue.items"
            :key="problemTypeName"
          >
            <v-expansion-panel-header>
              <p>
                {{ problemTypeName }}
              </p>
              <p>
                confirmations: {{ problemTypeValue.allConfirmations }}
              </p>
              <p>
                count: {{ problemTypeValue.items.length }}
              </p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <problems-item
                v-for="problem of problemTypeValue.items"
                :key="problem._id"
                :problem="problem"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
    <h3 v-else>
      No problems
    </h3>
  </div>
</template>

<script>
  import ProblemsItem from '@/components/problemsPage/ProblemsItem';
  import { mapGetters } from 'vuex';
  export default {
    name: 'ProblemsPage',
    components: {
      ProblemsItem,
    },
    computed: {
      ...mapGetters({
        problemsList: 'problems/problemsList',
      }),
      mappedProblems () {
        const mappedProblemsObj = {
          info: {
            allCount: 0,
            items: {},
          },
          warning: {
            allCount: 0,
            items: {},
          },
          error: {
            allCount: 0,
            items: {},
          },
        };

        for (const problem of this.problemsList.filter(problem => problem.status === 'new')) {
          if (!mappedProblemsObj[problem.level]) {
            mappedProblemsObj[problem.level] = {
              allCount: 0,
              items: {},
            };
          }

          mappedProblemsObj[problem.level].allCount++;

          if (!mappedProblemsObj[problem.level].items[problem.type]) {
            mappedProblemsObj[problem.level].items[problem.type] = {
              items: [],
              allConfirmations: 0,
            };
          }

          mappedProblemsObj[problem.level].items[problem.type].items.push(problem);
          mappedProblemsObj[problem.level].items[problem.type].allConfirmations += problem.confirmations;
        }

        return mappedProblemsObj;
      },
    },
  };
</script>

<style scoped>

</style>
